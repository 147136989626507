import "@nestoca/ui/style";
import "@shared/ui/styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import React, { ReactElement, ReactNode, useState, useEffect } from "react";

import { appWithTenant, useTenant } from "@nestoca/multi-tenant";
import { toastDefaultOptions } from "@nestoca/ui";
import { GoogleTagManager } from "@shared/analytics";
import { setBackendBaseUrl, setCommonHeaders } from "@shared/api/client";
import { Datadog, useDatadogTenant } from "@shared/datadog";
import { ConnectivityListener, Header, MaintenanceProvider } from "@shared/ui";
import { PartnerProvider } from "@shared/utils";
import { TenantEnvVarProvider } from "@shared/utils";
import { SSREnv } from "@shared/utils/server-side-env";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { inspect } from "@xstate/inspect";
import Debug from "debug";
import App, { AppProps, AppContext } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";

import { ErrorBoundary } from "@components/error-boundary";
import { AuthProvider } from "@lib/auth-provider";
import { UserProvider } from "@lib/user-provider";

import type { SSRConfig as MultiTenantSSRConfig } from "@nestoca/multi-tenant";
import type { SupportedLocales } from "@shared/constants";
import type { Partner } from "@shared/utils";
import type { ServideSideBackendApiProps } from "@shared/utils/common-server-props";
import type { DehydratedState } from "@tanstack/react-query";
import type { NextPage } from "next";
import type { SSRConfig as I18nNextSSRConfig } from "next-i18next";

const debug = Debug("nesto:cma");

export type NextPageWithLayout<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface PageProps
  extends I18nNextSSRConfig,
    MultiTenantSSRConfig,
    SSREnv,
    ServideSideBackendApiProps {
  dehydratedState: DehydratedState;
  applicationId: number;
  tenantPublicEnvVar: Record<string, string>;
  partner: Partner;
}

type AppPropsWithLayout<P = Record<string, unknown>> = AppProps<P> & {
  Component: NextPageWithLayout;
};

const isDev = process.env.NODE_ENV === "development";

// import mocking utils
if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("@test/msw/next");
}

/**
 * Uncomment this to enable xstate inspector
 */
// if (isDev && typeof window !== "undefined") {
//   inspect({
//     /* options */
//     iframe: false,
//   });
// }

const newQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      // @see https://github.com/TanStack/query/issues/5679
      networkMode: "offlineFirst",
    },
    mutations: {
      // @see https://github.com/TanStack/query/issues/5679
      networkMode: "offlineFirst",
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout<PageProps>) {
  const { enableMaintenanceCma, enableMaintenance } =
    pageProps._tenant?.tenant?.featureFlags || {};

  let didRunOnceInStrictMode = false;

  /**
   * This effect is used to prevent the user from leaving the page when there are unsaved changes.
   * It is only run once in strict mode to avoid memory leaks.
   * handle the logic in the onbeforeunload event handler because router.events do not exists in NextJS v13
   * @ref: https://github.com/vercel/next.js/discussions/9662#discussioncomment-6957704
   */

  useEffect(() => {
    if (!didRunOnceInStrictMode) {
      didRunOnceInStrictMode = true;

      const handleAnchorClick = (e: any) => {
        const targetUrl = e.currentTarget.href,
          currentUrl = window.location.href;
        if (targetUrl !== currentUrl) {
          if (window.onbeforeunload) {
            const res = window.onbeforeunload(e);
            if (!res) e.preventDefault();
          }
        }
      };

      const handleMutation = () => {
        const anchorElements = document.querySelectorAll("a[href]");
        anchorElements.forEach((anchor) =>
          anchor.addEventListener("click", handleAnchorClick)
        );
      };

      const mutationObserver = new MutationObserver(handleMutation);
      mutationObserver.observe(document, { childList: true, subtree: true });
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const axe = require("@axe-core/react");
      axe(React, ReactDOM, 1000);
    }
  }, [didRunOnceInStrictMode]);

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <main>
        <Header />
        {page}
      </main>
    ));

  const router = useRouter();
  const { rid } = router.query;
  if (rid) setCommonHeaders({ "x-login-on-behalf": rid as string });

  const tenant = useTenant();
  debug("tenant", tenant);
  useDatadogTenant(tenant);
  if (tenant) {
    setBackendBaseUrl(tenant.apiBaseUrl);
  }

  const gtmId = tenant?.analyticId || "GTM-PHH45NW";

  const [queryClient] = useState(() => newQueryClient);

  return (
    <ErrorBoundary>
      <Datadog
        enabled={!isDev}
        sessionReplayRecording
        applicationId={process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || ""}
        clientToken={process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || ""}
        site="datadoghq.com"
        service={process.env.NEXT_PUBLIC_DATADOG_SERVICE || "cma"}
        env={pageProps._env}
        version={process.env.NEXT_PUBLIC_DATADOG_VERSION || "latest"}
        defaultPrivacyLevel="mask-user-input"
        allowedTracingUrls={[
          (requestUrl = "") => {
            const url = new URL(requestUrl);

            // return false if the pathname start with `/napi` or `/_next`
            // we don't want to trace those requests
            // `/_next` is the nextjs internal path for static files
            // `/napi` is the path for the next version checker every N minutes
            const excludedPaths = [
              `${router.basePath}/napi`,
              `${router.basePath}/_next`,
            ];
            const isExcludedPath = excludedPaths.some((path) =>
              url.pathname.startsWith(path)
            );

            if (isExcludedPath) {
              return false;
            }

            const backendURL = new URL(pageProps.baseApiUrl);
            // we allow tracing for the backend url and the current window location (this frontend service on our K8S cluster)
            // allowed origin ["https://app.{env}.nesto.ca", "{scheme}://{env}.tenant-domain.tld"]
            // by default with this logic `tenant?.publicDomain` is not in the list and not allowed
            // ex: `tenant?.publicDomain` = `https://auth.tenant-domain.tld`
            const allowedOrigins = [backendURL.origin, window.location.origin];
            const isAllowedOrigin = allowedOrigins.includes(url.origin);

            return isAllowedOrigin;
          },
        ]}
        trackUserInteractions
        trackResources
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width,minimum-scale=1,initial-scale=1"
          />
          <link
            rel="icon"
            href={
              tenant?.logo?.secondary?.[router.locale as SupportedLocales]?.url
            }
          />
          <title>Application</title>
        </Head>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <TenantEnvVarProvider
              tenantPublicEnvVar={pageProps.tenantPublicEnvVar}
            >
              <MaintenanceProvider
                isEnabled={enableMaintenance || enableMaintenanceCma}
              >
                <AuthProvider>
                  <UserProvider>
                    <PartnerProvider partnerSSR={pageProps.partner}>
                      {getLayout(<Component {...pageProps} />)}
                      {/* inside UserProvider bacause the pageview need to get app type */}
                      {/* and to get it we need to auth token to do BE request */}
                      <GoogleTagManager gtmId={gtmId} />
                      <ConnectivityListener />
                    </PartnerProvider>
                  </UserProvider>
                </AuthProvider>
              </MaintenanceProvider>
            </TenantEnvVarProvider>
            <ToastContainer {...toastDefaultOptions} />
          </Hydrate>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </Datadog>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { query } = appContext.ctx;
  const { applicationId } = query;
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, applicationId };
};

// TODO: improve the types here `Type 'AppPropsWithLayout<PageProps>' does not satisfy the constraint 'AppProps'.`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTenant<AppPropsWithLayout<PageProps>>(
  appWithTranslation<AppPropsWithLayout<PageProps>>(MyApp)
);
